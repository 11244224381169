/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.3.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TokenStatisticResponse { 
    stockStatus: TokenStatisticResponse.StockStatusEnum;
    inStock: number;
    totalTokens: number;
    totalMarketCap: number;
    totalProfit: number;
    tokenPrice: number;
    currency: string;
}
export namespace TokenStatisticResponse {
    export type StockStatusEnum = 'in_stock' | 'out_of_stock' | 'below_margin';
    export const StockStatusEnum = {
        InStock: 'in_stock' as StockStatusEnum,
        OutOfStock: 'out_of_stock' as StockStatusEnum,
        BelowMargin: 'below_margin' as StockStatusEnum
    };
}


