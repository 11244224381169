<div class="row chart-container pt-3">
  <div class="col-12 pb-2 d-flex justify-content-center">
    <button [ngClass]="{'active': selectedFilter === 0}" class="btn-stats"
      (click)="getMetalIdAndRange(preciousMetalId, 'one_day', 0)">1D</button>
    <button [ngClass]="{'active': selectedFilter === 1}" class="btn-stats"
      (click)="getMetalIdAndRange(preciousMetalId, 'seven_days', 1)">7D</button>
    <button [ngClass]="{'active': selectedFilter === 2}" class="btn-stats"
      (click)="getMetalIdAndRange(preciousMetalId, 'one_month', 2)">1M</button>
    <button [ngClass]="{'active': selectedFilter === 3}" class="btn-stats"
      (click)="getMetalIdAndRange(preciousMetalId, 'three_months', 3)">3M</button>
    <button [ngClass]="{'active': selectedFilter === 4}" class="btn-stats"
      (click)="getMetalIdAndRange(preciousMetalId, 'one_year', 4)">1Y</button>
    <button [ngClass]="{'active': selectedFilter === 5}" class="btn-stats"
      (click)="getMetalIdAndRange(preciousMetalId, 'all', 5)">MAX</button>
  </div>

  <div class="container col pb-2 justify-content-center">
    <canvas baseChart [datasets]="lineChartData" [labels]="lineChartLabels" [type]="'line'"
      [options]="lineChartOptions"></canvas>
  </div>
</div>