import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public currentYear = new Date().getFullYear();
  userAvatarSubject = new BehaviorSubject<boolean>(false);
  currencyChangeSubject = new BehaviorSubject<string>('');


  constructor() { }

  userAvatarChanged(value: boolean) {
    this.userAvatarSubject.next(value);
  }

  currencyChanged(value: string) {
    this.currencyChangeSubject.next(value);
  }
}
