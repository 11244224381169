/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.3.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AuditPageRequestActionsInnerAnyOf2 } from './auditPageRequestActionsInnerAnyOf2';
import { AuditPageRequestActionsInnerAnyOf1 } from './auditPageRequestActionsInnerAnyOf1';
import { AuditPageRequestActionsInnerAnyOf4 } from './auditPageRequestActionsInnerAnyOf4';
import { AuditPageRequestActionsInnerAnyOf3 } from './auditPageRequestActionsInnerAnyOf3';
import { AuditPageRequestActionsInnerAnyOf } from './auditPageRequestActionsInnerAnyOf';
import { AuditPageRequestActionsInnerAnyOf6 } from './auditPageRequestActionsInnerAnyOf6';
import { AuditPageRequestActionsInnerAnyOf5 } from './auditPageRequestActionsInnerAnyOf5';
import { AuditPageRequestActionsInnerAnyOf8 } from './auditPageRequestActionsInnerAnyOf8';
import { AuditPageRequestActionsInnerAnyOf7 } from './auditPageRequestActionsInnerAnyOf7';


export interface AuditPageRequestActionsInner { 
}

