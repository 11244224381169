/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.3.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PreciousMetalTokenTransactionItem } from './preciousMetalTokenTransactionItem';


export interface PreciousMetalTokenTransactionsResponse { 
    totalDocs: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
    page: number;
    totalPages: number;
    pagingCounter: number;
    limit: number;
    docs: Array<PreciousMetalTokenTransactionItem>;
}

