import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Spinkit } from 'ng-http-loader';
import { fromEvent, Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public title = 'Orbiko Admin Portal';
  public spinkit = Spinkit;
  private onlineEvent: Observable<Event>;
  private offlineEvent: Observable<Event>;

  constructor(
    private toastr: MatSnackBar,
    private router: Router
  ) {
    const lastKnownStep = localStorage.getItem('next_step');

    switch (lastKnownStep) {
      case 'two_factor_authorization_setup':
        this.router.navigate(['/qr-authenticator']);
        break;

      case 'two_factor_authorization_enter_code':
        this.router.navigate(['/authentication']);
        break;

      case 'two_factor_authorization_backup_codes':
        this.router.navigate(['/backup-codes']);
        break;

      case 'investor-type':
        this.router.navigate(['/investor-type']);
        break;

      case 'physical-address':
        this.router.navigate(['/physical-address']);
        break;

      case 'company-details':
        this.router.navigate(['/company-details']);
        break;

      default:
        break;
    }

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.onlineEvent.subscribe(() => {
      this.toastr.open('Welcome back online :)', '', {
        panelClass: ['success-snackbar'],
        duration: 3000
      });
    });

    this.offlineEvent.subscribe(() => {
      this.toastr.open('Seems that you are offline :(', '', {
        panelClass: ['error-snackbar']
      });
    });
  }

}
