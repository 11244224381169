import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard {
  constructor(
    private jwtService: JwtHelperService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (localStorage.access_token || sessionStorage.access_token) {
      this.router.navigate(['/dashboard']);
      return false;
    }
    return true;
  }

}
