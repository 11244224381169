export * from './audit.service';
import { AuditService } from './audit.service';
export * from './auth.service';
import { AuthService } from './auth.service';
export * from './cart.service';
import { CartService } from './cart.service';
export * from './currency.service';
import { CurrencyService } from './currency.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './kyc.service';
import { KycService } from './kyc.service';
export * from './payment.service';
import { PaymentService } from './payment.service';
export * from './preciousMetal.service';
import { PreciousMetalService } from './preciousMetal.service';
export * from './stats.service';
import { StatsService } from './stats.service';
export * from './transaction.service';
import { TransactionService } from './transaction.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './wallet.service';
import { WalletService } from './wallet.service';
export const APIS = [AuditService, AuthService, CartService, CurrencyService, DefaultService, KycService, PaymentService, PreciousMetalService, StatsService, TransactionService, UserService, WalletService];
