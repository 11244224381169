import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { JwtModule } from '@auth0/angular-jwt';
import { ApiModule, BASE_PATH } from 'swagger-lib';
import { environment } from 'src/environments/environment';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';

export function tokenGetter() {
  return localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    ApiModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    JwtModule.forRoot({
      config: { tokenGetter }
    }),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: BASE_PATH,
      useFactory: () => environment.baseUrl
    },
    {
      provide: BsDropdownConfig,
      useValue: { autoClose: true }
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
