/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.3.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReferredPersonItemResponse } from './referredPersonItemResponse';


export interface ReferralInfoResponse { 
    link: string;
    code: string;
    numberOfPeopleReferred: number;
    commissionPercentage: number;
    monthlyAmountEarned: number;
    totalAmountEarned: number;
    amountEarnedCurrency: string;
    peopleReferred: Array<ReferredPersonItemResponse>;
}

