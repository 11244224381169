import { JwtService } from './../services/jwt.service';
import { AuthService } from 'swagger-lib/api/auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TokenResponse } from 'swagger-lib';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private jwtService: JwtService,
    private authService: AuthService,
    private toastr: MatSnackBar
  ) { }

  actionTokenUrls: string[] = [
    'backup-codes',
    'two-factor-validate',
    'resend-email-verification',
    'verify-email',
    '2fa-setup',
    '2fa-authorization',
  ];

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = this.addAccessToken(request);
    return next.handle(request).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          // TODO: 'handle response status
        }
      }),

      catchError((error: HttpErrorResponse) => {
        // don't logout the user if he enters wrong two-factor-validation code, let him retry another time
        if ([401, 403].indexOf(error.status) !== -1 && !error.url?.includes('two-factor-validate') && !error.url?.includes('2fa-authorization')) {
          // if access token expired, refresh token valid
          if (this.jwtService.isAccessTokenExpired() && !this.jwtService.isRefreshTokenExpired() && this.jwtService.refreshToken) {
            return this.authService.authRefreshPost({ refreshToken: this.jwtService.refreshToken }).pipe(switchMap((res: TokenResponse) => {
              this.jwtService.setSession(res.accessToken, res.refreshToken);
              return next.handle(this.addAccessToken(request));
            }), catchError(refreshError => {
              if (refreshError instanceof HttpErrorResponse) {
                // if refresh token is expired
                if ([401, 403].indexOf(refreshError.status) !== -1) {
                  this.jwtService.logout();
                }
              }
              return throwError(refreshError);
            }));
          } else {
            this.jwtService.logout();
          }
        }

        if (error.error.code === 'ERR_OSSL_BAD_DECRYPT') {
          this.toastr.open('You have entered wrong password. Please try again.', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        } else if (error.statusText === 'Unknown Error') {
          this.toastr.open('Ooops, something went wrong. Please try again later.', '', {
            duration: 3000,
            panelClass: ['error-snackbar']
          });
        } else if (error.error && error.error.message && error.error.message !== 'token not found in user wallet') {
          const errorMessage = error.error.message.charAt(0).toUpperCase() + error.error.message.slice(1);

          if (errorMessage.includes('E11000 dupclicate key error collection')) {
            this.toastr.open('User already registered with entered email', '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          } else {
            this.toastr.open(errorMessage, '', {
              duration: 3000,
              panelClass: ['error-snackbar']
            });
          }
        }
        return throwError(error);
      }));
  }

  // If there is access token add to Headers
  addAccessToken(request: HttpRequest<unknown>) {
    const token = this.jwtService.accessToken;

    if (this.actionTokenUrls.some(item => request.url.includes(item))) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.jwtService.actionToken}`
        }
      });
    } else if (request.url.includes('stats/chart/')) {
      return request.clone({
        setHeaders: {
          Authorization: environment.apiKey
        }
      });
    } else {
      if (token) {
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.jwtService.accessToken}`
          }
        });
      }
    }
    return request;
  }
}
