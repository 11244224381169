/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.3.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PlaceOrderRequest { 
    method: PlaceOrderRequest.MethodEnum;
}
export namespace PlaceOrderRequest {
    export type MethodEnum = 'demo' | 'coinify' | 'card' | 'eft' | 'wallet';
    export const MethodEnum = {
        Demo: 'demo' as MethodEnum,
        Coinify: 'coinify' as MethodEnum,
        Card: 'card' as MethodEnum,
        Eft: 'eft' as MethodEnum,
        Wallet: 'wallet' as MethodEnum
    };
}


