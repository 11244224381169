import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  userInfo = new BehaviorSubject<null>(null);
  jwtHelperService = new JwtHelperService();

  constructor(private router: Router, private http: HttpClient) { }

  validate(): void {
    if (this.accessToken && !this.isAccessTokenExpired) {
      this.userInfo.next(this.jwtHelperService.decodeToken(this.accessToken));
    }
  }

  public setSession(accessToken: string, refreshToken: string): void {
    if (!this.jwtHelperService.isTokenExpired(accessToken)) {
      if (localStorage.getItem('action_token')) {
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('refresh_token', refreshToken);
      } else {
        sessionStorage.setItem('access_token', accessToken);
        sessionStorage.setItem('refresh_token', refreshToken);
      }
      this.userInfo.next(this.jwtHelperService.decodeToken(accessToken));
    } else {
      this.userInfo.next(null);
    }
  }

  // get Access token expiration date
  getTokenExpDate(): Date | null {
    if (!this.accessToken)
      return null;
    return this.jwtHelperService.getTokenExpirationDate(this.accessToken);
  }

  // check if Access token is expired
  isAccessTokenExpired(): boolean {
    if (!this.accessToken)
      return true;
    return this.jwtHelperService.isTokenExpired(this.accessToken);
  }

  // check if Refresh token is expired
  isRefreshTokenExpired(): boolean {
    if (!this.refreshToken)
      return true;
    return this.jwtHelperService.isTokenExpired(this.refreshToken);
  }

  // return Access token or null
  get accessToken(): string | null {
    const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');
    if (!token)
      return null;
    return token;
  }

  // return Refresh token or null
  get refreshToken(): string | null {
    const token = localStorage.getItem('refresh_token') || sessionStorage.getItem('refresh_token');
    if (!token)
      return null;
    return token;
  }

  // return Action token or null
  get actionToken(): string | null {
    const token = localStorage.getItem('action_token') || sessionStorage.getItem('action_token');
    if (!token)
      return null;
    return token;
  }

  logout(): void {
    this.userInfo.next(null);
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['/login']);
  }

}
