/**
 * api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.3.3
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CoinifyWebhookContextPaymentsInner } from './coinifyWebhookContextPaymentsInner';


export interface CoinifyWebhookContext { 
    id?: string;
    customerId?: string;
    amount?: string;
    currency?: string;
    creditAmount?: string;
    creditCurrency?: string;
    payments?: Array<CoinifyWebhookContextPaymentsInner>;
}

