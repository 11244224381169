import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartConfiguration, ChartDataset } from 'chart.js';
import { SharedService } from 'src/app/services/shared.service';
import { StatsService, TokenPriceChartResponseInner } from 'swagger-lib';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit, OnChanges {
  @Input() preciousMetalId!: string | null;
  // range!: string | null;
  selectedFilter = 1;
  selectedCurrency: string;

  lineChartData: ChartDataset[] = [{
    data: [],
    label: 'Price',
    borderColor: '#f28900',
    backgroundColor: 'transparent',
    pointBackgroundColor: '#f2890080',
    pointBorderColor: '#f28900',
    pointHoverBackgroundColor: '#f2890080',
    pointHoverBorderColor: '#f28900'
  }];

  lineChartLabels: string[] = [];
  lineChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    color: 'gray',
    scales: {
      x: {
        grid: {
          color: 'transparent'
        },
        ticks: {
          color: '#7b838f'
        }
      },
      y: {
        position: 'left',
        grid: {
          color: 'lightgray',
        },
        ticks: {
          color: '#7b838f'
        }
      },
    },
  };

  constructor(
    private statsService: StatsService,
    private ac: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService
  ) {
    if (this.router.url.includes('chart')) {
      this.lineChartOptions = {
        color: 'lightgray',
        scales: {
          x: {
            grid: {
              color: 'transparent'
            },
            ticks: {
              color: 'lightgray'
            }
          },
          y: {
            position: 'left',
            grid: {
              color: 'lightgray',
            },
            ticks: {
              color: 'lightgray'
            }
          },
        },
      };
    }

    this.sharedService.currencyChangeSubject.subscribe(res => {
      if (res) {
        localStorage.setItem('selected_currency', res);
        this.selectedCurrency = res;
        this.getMetalIdAndRange(this.preciousMetalId, 'one_day');
      }
    });

    const storedCurrency = localStorage.getItem('selected_currency') || localStorage.getItem('default_currency') || 'USD';

    this.selectedCurrency = storedCurrency;
  }

  ngOnInit(): void {
    this.ac.paramMap.subscribe(paramMap => {
      if (paramMap.get('preciousMetalId')) {
        this.preciousMetalId = paramMap.get('preciousMetalId');
        this.getMetalIdAndRange(this.preciousMetalId, 'one_day');

      }

      // if (paramMap.get('range')) {
      //   this.range = paramMap.get('range');
      // }
    });
  }

  ngOnChanges(): void {
    if (this.preciousMetalId) {
      this.getMetalIdAndRange(this.preciousMetalId, 'one_day');
    }
  }

  // get metal id from url
  getMetalIdAndRange(metalId: string | null, range: any, id = 0) {
    this.selectedFilter = id;
    const metalIdToSend = metalId as string;

    this.statsService.statsChartPreciousMetalIdRangeRangeCurrencyCurrencyGet(this.selectedCurrency, metalIdToSend, range).subscribe((res: TokenPriceChartResponseInner[]) => {
      this.lineChartLabels = [];
      this.lineChartData[0].data = [];

      res.forEach(el => {
        this.lineChartLabels.push(el.label);
        this.lineChartData[0].data.push(el.data);
      });
    });
  }
}
