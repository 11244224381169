import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { JwtService } from '../services/jwt.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  constructor(
    private jwtHelperService: JwtHelperService,
    private jwtService: JwtService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (!localStorage.access_token && !sessionStorage.access_token) {
      if (!this.jwtHelperService.decodeToken(this.jwtService.accessToken || '').rle.includes('admin')) {
        this.router.navigate(['/login']);
        return false;
      }
    }
    return true;
  }
}
